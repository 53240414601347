import { useState, useEffect, useCallback, useMemo } from 'react'

import map from 'lodash/map'

declare const AUSTRA

export function useTableCheckboxes() {
  const [disabled, setDisabled] = useState(true)
  const [activeCheckboxes, setActiveCheckboxes] = useState<Array<HTMLInputElement>>([])

  const [persistedRecordIds, setPersistedRecordIds] = useState<Array<string>>(
    AUSTRA.CheckboxTable.getSelectedIdsFromLocalStorage()
  )

  const selectedRecordIds = useMemo((): Array<string> => {
    return map(activeCheckboxes, (node) => node.dataset.recordId)
  }, [activeCheckboxes])

  const findActiveCheckboxes: () => Array<HTMLInputElement> = useCallback(() => {
    return Array.from(table().querySelectorAll("td input.datagrid-checkbox[type='checkbox']:checked"))
  }, [])

  function table(): HTMLElement {
    return document.querySelector('table.datagrid.table-with-checkboxes')
  }

  const checkboxesChanged = useCallback(() => {
    const actives = findActiveCheckboxes()
    const persistedRecordIds = AUSTRA.CheckboxTable.getSelectedIdsFromLocalStorage()
    setActiveCheckboxes(actives)
    setPersistedRecordIds(persistedRecordIds)
    setDisabled(actives.length == 0)
  }, [findActiveCheckboxes])

  useEffect(() => {
    document.addEventListener('austra-table-checkbox-change', checkboxesChanged)
    return () => {
      document.removeEventListener('austra-table-checkbox-change', checkboxesChanged)
    }
  }, [checkboxesChanged])

  return { table, disabled, setDisabled, activeCheckboxes, selectedRecordIds, persistedRecordIds }
}
