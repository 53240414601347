import React, { LegacyRef, useContext, useState } from 'react'

import { CartItems, Checkout } from './utils'

import { useClickOutside } from 'hooks'
import { t } from 'i18n'
import round from 'lodash/round'
import sumBy from 'lodash/sumBy'

const CartItem = ({ cartItem }) => {
  return (
    <div className='cart_item'>
      <div className='cart_item__name'>{cartItem.name}</div>
      <div className='cart_item__price'>
        {cartItem.price.including_vat.toFixed(2)} {cartItem.price.currency}
      </div>
    </div>
  )
}

const CartContainer = ({ onClose }) => {
  const { cartItems, clearCart } = useContext(CartItems)
  const { openCheckoutModal } = useContext(Checkout)

  const ref: LegacyRef<HTMLDivElement> = useClickOutside(onClose)

  const totalPriceIncludingVAT = round(
    sumBy(cartItems, (cartItem) => cartItem.price.including_vat),
    2
  )

  const totalPriceExcludingVAT = round(
    sumBy(cartItems, (cartItem) => cartItem.price.excluding_vat),
    2
  )

  const totalVAT = totalPriceIncludingVAT - totalPriceExcludingVAT

  const onCheckout = () => {
    openCheckoutModal()
    onClose()
  }

  const onClearCart = () => {
    clearCart()
    onClose()
  }

  return (
    <div className='cart' onClick={(e) => e.stopPropagation()} ref={ref}>
      <div className='cart__items_container'>
        {cartItems.map((cartItem, index) => (
          <CartItem key={index} cartItem={cartItem} />
        ))}
      </div>

      <div className='cart__prices'>
        <div className='cart__prices_labels'>
          <div>{t('store.products.total_without_vat')}</div>

          <div>{t('store.products.vat', { vat_percentage: '21' })}</div>

          <div>
            <b>{t('store.products.total_with_vat')}</b>
          </div>
        </div>

        <div className='cart__prices_prices'>
          <div>{totalPriceExcludingVAT.toFixed(2)} EUR</div>
          <div>{totalVAT.toFixed(2)} EUR</div>
          <div>
            <b>{totalPriceIncludingVAT.toFixed(2)} EUR</b>
          </div>
        </div>
      </div>

      <div className='cart__actions_container'>
        <button className='store__primary_button' onClick={onCheckout}>
          {t('store.products.order')}
        </button>
        <button className='store__danger_button' onClick={onClearCart}>
          {t('store.products.clear_basket')}
        </button>
      </div>
    </div>
  )
}

const Cart = () => {
  const [cartOpen, setCartOpen] = useState(false)

  const { cartItems } = useContext(CartItems)

  const hasItems = cartItems.length > 0

  return (
    <div
      key={cartItems.length}
      className={`navigation__cart_button ${hasItems || 'navigation__cart_button--inactive'}`}
      onClick={() => hasItems && setCartOpen((wasCartOpen) => !wasCartOpen)}
    >
      <i className='fa fa-shopping-cart fa-2x' />

      {cartOpen && <CartContainer onClose={() => setCartOpen(false)} />}
    </div>
  )
}

export default Cart
