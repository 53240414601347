import React from 'react'

type SpinnerSize = 'sm' | 'md'

// TO DO: Use this throughout system
const Spinner: React.FC<{ size?: SpinnerSize }> = ({ size = 'md' }) => {
  const sizeClass = size === 'md' ? 'fa-2x' : ''

  return <i className={`fa fa-spinner fa-spin ${sizeClass}`} />
}

export default Spinner
