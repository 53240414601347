import React, { ReactElement, useMemo } from 'react'

import { isArray } from 'lodash'
import ReactSelect, { Props as ReactSelectProps } from 'react-select'

const customStyles = {
  control: (provided, _) => ({
    ...provided,
    height: 'auto',
    minHeight: 30
  }),
  dropdownIndicator: (provided, _) => ({
    ...provided,
    padding: 5
  }),
  menu: (provided, _) => ({
    ...provided,
    marginTop: 0
  })
}

type SelectProps<T> = ReactSelectProps<T, boolean>
export type SelectOption = Record<never, never>

type SimpleSelectProps<T = SelectOption | SelectOption[]> = Omit<SelectProps<T>, 'onChange'> & {
  optionLabelKey?: string
  optionValueKey?: string
  onChange: (value: string | string[]) => void
}

type SelectFC<T = SelectOption | SelectOption[], P = SimpleSelectProps<T>> = (props: P) => ReactElement

export const SimpleSelect: SelectFC = ({
  optionLabelKey = 'label',
  optionValueKey = 'value',
  getOptionLabel,
  getOptionValue,
  value,
  options,
  onChange,
  ...props
}) => {
  getOptionValue ??= (option) => (option ? option[optionValueKey] || '' : '')
  getOptionLabel ??= (option) => (option ? option[optionLabelKey] || '' : '')

  const selectedOption = useMemo(
    () =>
      isArray(value)
        ? options.filter((option) => value.includes(getOptionValue(option)))
        : options.find((option) => getOptionValue(option) === value) || value || {},
    [getOptionValue, options, value]
  )

  const processOption = (option: SelectOption | SelectOption[]) => {
    if (isArray(option)) {
      onChange(option.map((o) => getOptionValue(o)))
    } else {
      onChange(getOptionValue(option))
    }
  }

  return (
    <Select
      {...props}
      options={options}
      value={selectedOption}
      onChange={processOption}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
    />
  )
}

const Select = <T,>(props: SelectProps<T>) => {
  return <ReactSelect placeholder='' styles={customStyles} classNamePrefix='react-select' {...props} />
}

export default Select
