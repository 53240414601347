import React, { useState } from 'react'

import FormGroup from 'components/common/formGroup'
import { SelectOption } from 'components/common/select'
import Spinner from 'components/common/spinner'
import { ReactSelectField, SimpleField } from 'components/formik/fields'
import { Field, Form, FormikContext, useFormik, useFormikContext } from 'formik'
import { useAxios, useClickOutside } from 'hooks'
import I18n, { t } from 'i18n'
import { store_api_countries_path } from 'routes'
import * as yup from 'yup'

yup.setLocale({
  string: {
    email: 'jābūt derīgai e-pasta adresei'
  }
})

const PrivateCustomerForm = () => {
  return (
    <FormBlock title='contact_person_info'>
      <SimpleField name='private_fields.first_name' label={t('store.order_form.first_name')} required />
      <SimpleField name='private_fields.last_name' label={t('store.order_form.last_name')} required />
      <SimpleField name='private_fields.phone' label={t('store.order_form.phone_number')} required />
      <SimpleField name='private_fields.email' label={t('store.order_form.email')} required />
    </FormBlock>
  )
}

const LegalCustomerForm = () => (
  <FormBlock title='company_info'>
    <SimpleField name='legal_fields.company_name' label={t('store.order_form.company_name')} required />
    <SimpleField name='legal_fields.reg_number' label={t('store.order_form.reg_number')} required />
    <SimpleField name='legal_fields.vat_number' label={t('store.order_form.vat_number')} required />
  </FormBlock>
)

const Location = ({ countries = [], locationType = 'delivery' }) => (
  <FormBlock title={`${locationType}_address`}>
    <ReactSelectField
      name={locationType + '_location.country_id'}
      label={t('store.order_form.country')}
      options={countries}
      required={true}
    />
    <SimpleField name={locationType + '_location.city'} label={t('store.order_form.city')} required />
    <SimpleField name={locationType + '_location.zip'} label={t('store.order_form.zip')} required />
    <SimpleField name={locationType + '_location.address'} label={t('store.order_form.address')} required />

    {locationType == 'delivery' && (
      <SimpleField
        name='isAlternateBillingAddress'
        label={t('store.order_form.alternate_billing_address')}
        type='checkbox'
        className='d-flex align-items-center'
        inputClassName=''
      />
    )}
  </FormBlock>
)

const FormBlock = ({ title = null, children }) => (
  <div className='w-100 my-3'>
    {title && (
      <>
        <strong>{t(`store.order_form.block_titles.${title}`)}</strong>
        <hr className='mt-1' />
      </>
    )}

    {children}
  </div>
)

type CheckoutStatus = 'checkout' | 'submitting' | 'success'

const ModalContent = ({ status }) => {
  const {
    values: { type, isAlternateBillingAddress }
  } = useFormikContext<yup.InferType<typeof validationSchema>>()

  const { data: countries } = useAxios<SelectOption[]>({ url: store_api_countries_path({ locale: I18n.locale }) })

  if (status === 'checkout') {
    return (
      <>
        <FormGroup inputId='load-contact' label={t('store.order_form.ordered_by')} required={true}>
          <label>
            <Field type='radio' name='type' value='private' checked={type === 'private'} />
            <span className='pl-1'>{t('store.order_form.natural_person')}</span>
          </label>
          <label className='ml-4'>
            <Field type='radio' name='type' value='legal' checked={type === 'legal'} />
            <span className='pl-1'>{t('store.order_form.legal_person')}</span>
          </label>
        </FormGroup>

        <PrivateCustomerForm />
        {type === 'legal' && <LegalCustomerForm />}
        <Location countries={countries} />
        {isAlternateBillingAddress && <Location countries={countries} locationType='billing' />}

        <FormBlock>
          <SimpleField name='order_description' label={t('store.order_form.order_description')} as='textarea' />
        </FormBlock>
      </>
    )
  } else if (status === 'submitting') {
    return <Spinner />
  } else if (status === 'success') {
    return (
      <>
        <div>{t('store.notifications.success.header')}</div>
        <div>{t('store.notifications.success.body')}</div>
        <div>{t('store.notifications.success.footer')}</div>
        <div>{t('store.notifications.success.signature')}</div>
      </>
    )
  }
}

const LOCATION_FIELDS = ['address', 'city', 'zip', 'country_id']
const PRIVATE_FIELDS = ['first_name', 'last_name', 'phone', 'email']
const LEGAL_FIELDS = ['company_name', 'reg_number', 'vat_number']

const locationSchema = {
  address: yup.string().required(),
  city: yup.string().required(),
  zip: yup.string().required(),
  country_id: yup.string().required()
}
const privateSchema = {
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().email().required()
}
const legalSchema = {
  company_name: yup.string().required(),
  reg_number: yup.string().required(),
  vat_number: yup.string().required()
}

const validationSchema = yup.object().shape({
  type: yup.string().oneOf(['private', 'legal']),
  isAlternateBillingAddress: yup.bool(),
  order_description: yup.string(),
  private_fields: yup.object().shape(privateSchema),
  legal_fields: yup.object().when('type', {
    is: 'legal',
    then: (schema) => schema.shape(legalSchema)
  }),
  delivery_location: yup.object().shape(locationSchema),
  billing_location: yup.object().when('isAlternateBillingAddress', {
    is: true,
    then: (schema) => schema.shape(locationSchema)
  })
})

const INITIAL_VALUES = {
  type: 'private',
  isAlternateBillingAddress: false,
  private_fields: {
    ...Object.fromEntries(PRIVATE_FIELDS.map((name) => [name, '']))
  },
  legal_fields: {
    ...Object.fromEntries(LEGAL_FIELDS.map((name) => [name, '']))
  },
  delivery_location: {
    ...Object.fromEntries(LOCATION_FIELDS.map((name) => [name, '']))
  },
  billing_location: {
    ...Object.fromEntries(LOCATION_FIELDS.map((name) => [name, '']))
  },
  order_description: ''
}

const CheckoutModal = ({ onSubmit, onClose }) => {
  const [checkoutStatus, setCheckoutStatus] = useState<CheckoutStatus>('checkout')

  const formProps = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit: (values) => {
      setCheckoutStatus('submitting')
      const private_fields = values.private_fields
      const legal_fields = values.type === 'legal' ? values.legal_fields : {}

      const orderDescription = values.order_description

      const deliveryLocationFields = values.delivery_location
      const billingLocationFields = values.isAlternateBillingAddress ? values.billing_location : deliveryLocationFields

      onSubmit({
        client: { type: values.type, ...private_fields, ...legal_fields },
        delivery_location: deliveryLocationFields,
        billing_location: billingLocationFields,
        order_description: orderDescription
      }).then(() => {
        setCheckoutStatus('success')
      })
    }
  })

  const buttonDisabled = checkoutStatus !== 'checkout' || !formProps.isValid

  const ref: React.LegacyRef<HTMLDivElement> = useClickOutside(onClose)

  return (
    <FormikContext.Provider value={formProps}>
      <div className='checkout_modal_container'>
        <div className='checkout_modal' ref={ref}>
          <div className='checkout_modal__header'></div>

          <Form>
            <ModalContent status={checkoutStatus} />
          </Form>

          <div className='checkout_modal__footer'>
            {checkoutStatus !== 'success' && (
              <button className='store__primary_button' onClick={formProps.submitForm} disabled={buttonDisabled}>
                {t('store.order_form.confirm')}
              </button>
            )}
          </div>
        </div>
      </div>
    </FormikContext.Provider>
  )
}

export default CheckoutModal
