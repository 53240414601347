import { createContext } from 'react'

import { Item } from './types'

interface CartItemsContext {
  cartItems: Item[]
  addItem: (item: Item) => void
  clearCart: () => void
}

export const CartItems = createContext<CartItemsContext>({
  cartItems: [],
  addItem: () => {},
  clearCart: () => {}
})

interface CheckoutContext {
  openCheckoutModal: () => void
}

export const Checkout = createContext<CheckoutContext>({
  openCheckoutModal: () => {}
})
