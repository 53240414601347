import React from 'react'

import Cart from './Cart'
import Locales from './Locales'

const NavBar = ({ logoUrl }) => {
  return (
    <div className='header'>
      <div className='header__logo'>{logoUrl && <img src={logoUrl} />}</div>
      <div className='header__navigation'>
        <Cart />
      </div>
      <Locales />
    </div>
  )
}

export default NavBar
