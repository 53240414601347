import { useEffect, useReducer } from 'react'

import axios, { AxiosRequestHeaders, AxiosResponse, Method } from 'axios'
import { stringify } from 'qs'

export const paramsSerializer = (params) => stringify(params, { arrayFormat: 'brackets' })

export type UseAxiosState = {
  loading: boolean
  error?: unknown
  data?: unknown
}

type UseAxiosAction = {
  type: 'success' | 'failure' | 'start'
  payload?: unknown
}

const initialState: UseAxiosState = {
  loading: true,
  error: undefined,
  data: undefined
}

const reducer = (state: UseAxiosState, action: UseAxiosAction): UseAxiosState => {
  switch (action.type) {
    case 'start':
      return { loading: true }
    case 'success':
      return { loading: false, data: action.payload }
    case 'failure':
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

interface AxiosConfig<T> {
  url: string
  params?: any
  method?: Method
  headers?: AxiosRequestHeaders
  onCompleted?: (response: AxiosResponse<T>) => void
}

const defaultAxiosConfig = { method: 'GET' }

export function useAxios<T = any>(config: AxiosConfig<T>) {
  const { url, params, method, headers, onCompleted } = { ...defaultAxiosConfig, ...config }
  const [{ loading, error, data }, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'start' })

    const cancelToken = axios.CancelToken.source()

    const getRequest = method === 'GET'

    axios({
      url,
      method,
      params: getRequest ? params : undefined,
      cancelToken: cancelToken.token,
      paramsSerializer: getRequest ? paramsSerializer : undefined,
      data: getRequest ? undefined : params,
      headers: { Accept: 'application/json', ...headers }
    })
      .then((response) => {
        dispatch({ type: 'success', payload: response.data })

        if (onCompleted && typeof onCompleted === 'function') {
          onCompleted(response)
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch({ type: 'failure', payload: error })
        }
      })

    return () => {
      cancelToken.cancel()
    }
  }, [url, params])

  return {
    loading,
    error,
    data: data as T
  }
}
