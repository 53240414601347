import React from 'react'

import StoreHome from './components/StoreHome'

import { createRoot } from 'react-dom/client'

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('store-root')
  const root = createRoot(container)

  root.render(<StoreHome logoUrl={container.dataset.logoUrl} />)
})
