import React, { InputHTMLAttributes, useContext, useEffect, useRef, useState } from 'react'

import { Product, ProductOption, Price, Item } from './types'
import { CartItems } from './utils'

import axios from 'axios'
import FormGroup from 'components/common/formGroup'
import { SimpleSelect } from 'components/common/select'
import Spinner from 'components/common/spinner'
import I18n, { t } from 'i18n'
import produce from 'immer'
import { every } from 'lodash'
import { DebounceInput } from 'react-debounce-input'
import { store_api_product_price_path } from 'routes'

interface OptionInputProps {
  option: ProductOption
  value: any
  onChange: (option: ProductOption, value: string | number) => void
  toggleInputValidity: (id: number, isValid: boolean) => void
}

const OptionInput: React.FC<OptionInputProps> = ({ option, value, onChange, toggleInputValidity }) => {
  const [validationMessage, setValidationMessage] = useState('')

  useEffect(() => {
    if (option.kind === 'select') {
      return
    }

    let message = null
    if (value !== '' && (value < 0 || value < option.min)) {
      message = I18n.t('errors.messages.greater_than_or_equal_to', { count: option.min || 0 })
    } else if (option.max && value > option.max) {
      message = I18n.t('errors.messages.less_than_or_equal_to', { count: option.max })
    }

    toggleInputValidity(option.id, !message)
    setValidationMessage(message)
  }, [value])

  let InputComponent

  if (option.kind === 'select') {
    InputComponent = (
      <SimpleSelect
        name={option.id.toString()}
        options={option.select_values}
        id={option.id.toString()}
        onChange={(value) => onChange(option, value as string)}
        optionValueKey='id'
        optionLabelKey='name'
        value={value}
      />
    )
  } else {
    const attributes: InputHTMLAttributes<HTMLInputElement> = {}

    if (option.kind === 'numeric') {
      attributes.type = 'number'
      attributes.min = option.min
      attributes.max = option.max
    }

    InputComponent = (
      <DebounceInput
        id={option.id.toString()}
        name={option.id.toString()}
        className={`form-control ${validationMessage ? 'is-invalid' : ''}`}
        value={value}
        onChange={({ target: { value } }) => onChange(option, value)}
        debounceTimeout={500}
        {...attributes}
      />
    )
  }

  return (
    <FormGroup label={option.name} inputId={option.id.toString()} vertical>
      {InputComponent}
      {validationMessage ? <small className='invalid-feedback'>{validationMessage}</small> : null}
    </FormGroup>
  )
}

const PriceBlock = ({ price, loading }) => {
  if (loading) {
    return <Spinner />
  }

  return (
    <>
      {t('store.products.price')}
      <b>
        {price.including_vat} {price.currency}
      </b>
    </>
  )
}

interface PriceResponse {
  id: number
  valid: boolean
  options: Record<string, number | string>
  price: Price
  visible_options: Record<string, number[]>
}

const ProductForm: React.FC<{ product: Product }> = ({ product }) => {
  const [productOptions, setProductOptions] = useState<ProductOption[]>(product.options)
  const [visibleOptions, setVisibleOptions] = useState<string[]>([])
  const [item, setItem] = useState<Item>(() => {
    const options = Object.fromEntries(
      product.options.map((opt) => [opt.id.toString(), opt.prefilled_value || undefined])
    )

    return {
      productId: product.id,
      name: product.name,
      options,
      price: null,
      modifiedOptions: [],
      valid: false
    }
  })

  const [inputValidity, setInputValidity] = useState({})

  const [loading, setLoading] = useState(true)

  const { addItem } = useContext(CartItems)

  const initialRender = useRef(true)

  const { price } = item

  const onChangeOption = (option: ProductOption, value: any) => {
    setItem(
      produce((item) => {
        item.options[option.id] = value

        if (!item.modifiedOptions.includes(option.id)) {
          item.modifiedOptions.push(option.id)
        }
      })
    )
  }

  useEffect(() => {
    if (initialRender.current || item.modifiedOptions.length > 0) {
      axios
        .post<PriceResponse>(store_api_product_price_path(product.id), {
          options: item.options,
          options_modified: item.modifiedOptions
        })
        .then(({ data: { price, options, valid, visible_options } }) => {
          setProductOptions(() => {
            const visibleOptions = product.options.filter((op) =>
              Object.keys(visible_options).includes(op.id.toString())
            )

            return visibleOptions.map((option) => {
              const newOptionSelectValueIds = visible_options[option.id]
              return {
                ...option,
                select_values: option.select_values.filter((value) => newOptionSelectValueIds.includes(value.id))
              }
            })
          })
          setVisibleOptions(Object.keys(options))
          setItem(
            produce((item) => {
              item.price = price

              item.options = options
              item.modifiedOptions = []

              const allOptionsFilled = every(
                Object.values(options),
                (optionValue) => optionValue != null || (typeof optionValue === 'string' && optionValue !== '')
              )

              item.valid = valid && allOptionsFilled
            })
          )
          setLoading(false)
        })

      if (initialRender.current) {
        initialRender.current = false
      }
    }
  }, [product, setItem, JSON.stringify(item.options)])

  const allVisibleFieldsFilled = every(
    visibleOptions,
    (optionId) => item.options[optionId] != null && item.options[optionId] !== ''
  )

  let error

  const inputsValid = (): boolean => Object.values(inputValidity).every(Boolean)

  if (!inputsValid() || (!loading && allVisibleFieldsFilled && item.valid === false)) {
    error = t('store.notifications.unable_to_calculate')
  }

  const toggleInputValidity = (id, isValid) =>
    setInputValidity((inputValidity) => ({ ...inputValidity, [id]: isValid }))

  return (
    <>
      <div className='store__product_form'>
        {productOptions.map((option) => {
          return (
            visibleOptions.includes(option.id.toString()) && (
              <div key={option.id} className='product_form__input'>
                <OptionInput
                  option={option}
                  value={item.options[option.id] || ''}
                  onChange={onChangeOption}
                  toggleInputValidity={toggleInputValidity}
                />
              </div>
            )
          )
        })}
      </div>
      <div className='product_price_row'>
        <div className='product_price'>
          {error ? (
            <div className='text-danger store__error'>{error}</div>
          ) : (
            <>
              <PriceBlock price={price} loading={loading} />
              <button className='store__primary_button' disabled={!item.valid || loading} onClick={() => addItem(item)}>
                <i className='fa fa-cart-plus' /> {t('store.products.add_to_basket')}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ProductForm
