import React from 'react'

import { CommonFieldProps } from '.'

import FormGroup from 'components/common/formGroup'
import { useField } from 'formik'

declare const AUSTRA

type Props = { options: [string, string][] } & CommonFieldProps

export const SelectField: React.FC<Props> = ({ label, className, required = false, ...props }) => {
  return (
    <FormGroup className={className} label={label} required={required} {...props}>
      <SelectFieldSelect {...props} label={label} />
    </FormGroup>
  )
}

export const SelectFieldSelect: React.FC<Props> = ({ placeholder, options, disabled, ...props }) => {
  const [field, meta] = useField(props)
  const { error } = meta

  return (
    <>
      <AUSTRA.RC.Select {...field} {...props} disabled={disabled} options={options} placeholder={placeholder} />
      {error && <div className='text-danger'>{error}</div>}
    </>
  )
}
