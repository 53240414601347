import React from 'react'

import { CommonFieldProps } from '.'

import FormGroup from 'components/common/formGroup'
import { useField } from 'formik'

declare const AUSTRA

export const DatePickerField: React.FC<CommonFieldProps> = ({
  label,
  required = false,
  className,
  disabled,
  hideFormGroup,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)
  const { error } = meta
  const { setValue } = helpers

  return (
    <FormGroup
      className={className}
      label={label}
      required={required}
      dataContains={field.name}
      hideFormGroup={hideFormGroup}
    >
      <AUSTRA.RC.Datepicker {...field} onChange={(date) => setValue(date)} disabled={disabled} />
      {error && <div className='text-danger'>{error}</div>}
    </FormGroup>
  )
}
