import React from 'react'

import { CommonFieldProps } from '.'

import FormGroup from 'components/common/formGroup'
import { SelectOption, SimpleSelect } from 'components/common/select'
import { useField } from 'formik'
import { Props as ReactSelectProps } from 'react-select'

type Props<T> = ReactSelectProps<T | T[], boolean> & CommonFieldProps & { optionLabel?: string; optionValue?: string }

export const ReactSelectField: React.FC<Props<SelectOption | SelectOption[]>> = ({
  label,
  className,
  required = false,
  optionLabel = 'name',
  optionValue = 'id',
  hideFormGroup = false,
  vertical,
  ...props
}) => {
  const [{ value, name }, { error }, { setValue }] = useField(props)

  const onChange = (option: string | string[]) => {
    setValue(option)
  }

  return (
    <FormGroup
      className={className}
      label={label}
      required={required}
      dataContains={name}
      hideFormGroup={hideFormGroup}
      vertical={vertical}
    >
      <SimpleSelect
        {...props}
        name={name}
        value={value}
        onChange={onChange}
        optionLabelKey={optionLabel}
        optionValueKey={optionValue}
      />
      {error && <div className='text-danger'>{error}</div>}
    </FormGroup>
  )
}
