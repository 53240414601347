import React from 'react'

import { CommonFieldProps } from '.'

import FormGroup from 'components/common/formGroup'
import { Field, ErrorMessage } from 'formik'

export const SimpleField: React.FC<CommonFieldProps> = ({
  label,
  required = false,
  vertical = false,
  className,
  inputClassName = 'form-control',
  name,
  id,
  hideFormGroup = false,
  ...props
}) => (
  <FormGroup
    className={className}
    label={label}
    required={required}
    vertical={vertical}
    inputId={id}
    hideFormGroup={hideFormGroup}
  >
    <Field name={name} {...props} id={id} className={inputClassName} />
    <ErrorMessage name={name} component='div' className='text-danger' />
  </FormGroup>
)
