import { useEffect, useState } from 'react'

export function useRefluxStore<T = any>(store) {
  const [data, updateData] = useState<T>(() => store.getInitialState())

  useEffect(() => {
    return store.listen(updateData)
  }, [store])

  return data
}
