import React, { useMemo, useState } from 'react'

import ProductForm from './ProductForm'
import { Product } from './types'

import Spinner from 'components/common/spinner'
import { useAxios } from 'hooks'
import I18n from 'i18n'
import sortBy from 'lodash/sortBy'
import { store_api_products_path } from 'routes'

type ProductsResponse = Product[]

const Products = () => {
  const [selectedProduct, setSelectedProduct] = useState<Product>()

  const { data: products, loading } = useAxios<ProductsResponse>({
    url: store_api_products_path({ locale: I18n.locale })
  })

  const productsWithOrderedOptions = useMemo(() => {
    return (products || []).map((product) => {
      const sortedOptions = sortBy(product.options, 'sequence')
      product.options = sortedOptions
      return product
    })
  }, [products])

  return (
    <div className='store__products'>
      <div className='store__product_list'>
        {loading ? (
          <Spinner />
        ) : (
          productsWithOrderedOptions.map((product) => (
            <div
              key={product.id}
              className={`store__product_list_item ${
                selectedProduct === product && 'store__product_list_item--active'
              }`}
              onClick={() => setSelectedProduct(product)}
            >
              {product.name}
            </div>
          ))
        )}
      </div>
      <div className='store__product_form_container'>
        {selectedProduct && <ProductForm key={selectedProduct.id} product={selectedProduct} />}
      </div>
    </div>
  )
}

export default Products
