import React from 'react'

import { CommonFieldProps } from '.'

import FormGroup from 'components/common/formGroup'
import { useField } from 'formik'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { DEFAULT_DATE_FORMAT } from 'utils'

export type NewDatePickerFieldProps = CommonFieldProps &
  Partial<ReactDatePickerProps> & { datePickerClassName?: string }

export const NewDatePickerField: React.FC<NewDatePickerFieldProps> = ({
  label,
  required = false,
  className,
  disabled,
  dateFormat = DEFAULT_DATE_FORMAT,
  datePickerClassName,
  ...props
}) => {
  const [field, meta, helpers] = useField<Date>(props)
  const { value, ...fieldProps } = field
  const { error } = meta
  const { setValue } = helpers

  return (
    <FormGroup
      className={className}
      label={label}
      required={required}
      dataContains={field.name}
      hideFormGroup={props.hideFormGroup}
    >
      <DatePicker
        {...fieldProps}
        className={datePickerClassName}
        selected={value}
        dateFormat={dateFormat}
        onChange={(date) => setValue(date)}
        disabled={disabled}
        calendarStartDay={1}
      />
      {error && <div className='text-danger'>{error}</div>}
    </FormGroup>
  )
}
