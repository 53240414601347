import React from 'react'

import { Query } from 'graphqlSchema'

import { OperationVariables, TypedDocumentNode, useQuery } from '@apollo/client'
import { get, throttle } from 'lodash'
import { GroupBase } from 'react-select'
import AsyncSelect, { AsyncProps } from 'react-select/async'
import styled from 'styled-components'

export const StringMultiSelectContainer = styled.div`
  border-width: 1px;

  [class*='-control'] {
    min-height: 31px;
    border-color: #ccc;
    align-items: flex-start;
    border-radius: 3px;
    &:hover {
      box-shadow: none;
    }
  }

  [class*='-placeholder'] {
    color: #999;
  }

  [class*='-indicatorContainer'] {
    padding: 5px;
  }
`

export interface RemoteReactSelectAsyncProps<Option> extends AsyncProps<Option, boolean, GroupBase<Option>> {
  defaultFilters?: object
  query: TypedDocumentNode<Query, OperationVariables>
  filter?: string
  transform?: (nodes: TypedDocumentNode[]) => Option[]
}

function RemoteReactSelectAsync<Option = unknown>({
  defaultOptions = true,
  isClearable = false,
  isMulti = false,
  noOptionsMessage = () => null,
  defaultFilters = {},
  query,
  filter = 'fullText',
  transform,
  ...props
}: RemoteReactSelectAsyncProps<Option>): JSX.Element {
  const { refetch } = useQuery<Query>(query, { variables: { filter: defaultFilters }, skip: true })

  const queryName = get(query, 'definitions[0].selectionSet.selections[0].name.value')

  const loadOptions: RemoteReactSelectAsyncProps<Option>['loadOptions'] = (inputText, callback: (options) => void) => {
    if (props.isDisabled) {
      return
    }

    const filters = { ...defaultFilters, [filter]: inputText }

    refetch({ filter: filters }).then((queryResult) => {
      const nodes = queryResult.data[queryName].nodes

      callback(transform ? transform(nodes) : nodes)
    })
  }

  const loadOptionsThrottled = throttle(loadOptions, 600, { leading: false })

  return (
    <StringMultiSelectContainer>
      <AsyncSelect
        key={JSON.stringify(defaultFilters)}
        classNamePrefix='react-select-async'
        defaultOptions={defaultOptions}
        isClearable={isClearable}
        isMulti={isMulti}
        loadOptions={loadOptionsThrottled}
        noOptionsMessage={noOptionsMessage}
        {...props}
      />
    </StringMultiSelectContainer>
  )
}

export default RemoteReactSelectAsync
