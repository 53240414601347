import { MutableRefObject, useEffect, useRef } from 'react'

export const useClickOutside = <T extends HTMLElement>(callback: () => void, enabled = true): MutableRefObject<T> => {
  const ref = useRef<T>()

  const handleClick = (e: MouseEvent) => {
    if ((e.target as HTMLElement).isConnected && ref.current && !ref.current.contains(e.target as Node)) {
      callback()
    }
  }

  useEffect(() => {
    if (enabled) {
      document.addEventListener('mousedown', handleClick)
      document.addEventListener('touchstart', handleClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleClick)
      document.removeEventListener('touchstart', handleClick)
    }
  })

  return ref
}
