import React from 'react'

import { CommonFieldProps } from '.'

import FormGroup from 'components/common/formGroup'
import RemoteReactSelectAsync, { RemoteReactSelectAsyncProps } from 'components/common/remoteReactSelectAsync'
import { useField } from 'formik'
import { MultiValue } from 'react-select'

export interface SelectOption {
  id: string
  name: string
}
interface SelectProps {
  onChange?: (option: SelectOption | MultiValue<SelectOption> | null) => void
  optionLabel?: string
}

export type AsyncSelectFieldProps = RemoteReactSelectAsyncProps<SelectOption> & CommonFieldProps & SelectProps

export const AsyncSelectField: React.FC<AsyncSelectFieldProps> = ({
  className,
  label,
  required,
  onChange,
  dataContains,
  hideFormGroup = false,
  optionLabel = 'name',
  ...props
}) => {
  const [{ value }, { error }, { setValue }] = useField<SelectOption | MultiValue<SelectOption> | null>(props)

  return (
    <FormGroup
      className={className}
      label={label}
      required={required}
      hideFormGroup={hideFormGroup}
      dataContains={dataContains}
    >
      <RemoteReactSelectAsync<SelectOption>
        {...props}
        value={value}
        onChange={(option) => {
          option ??= { id: '', name: '' }
          onChange ? onChange(option) : setValue(option)
        }}
        getOptionLabel={(option) => option[optionLabel] || ''}
        getOptionValue={(option) => option.id || ''}
      />
      {error && <div className='text-danger'>{error}</div>}
    </FormGroup>
  )
}
