import React, { useState } from 'react'

import CheckoutModal from './CheckoutModal'
import NavBar from './NavBar'
import Products from './Products'
import { Item } from './types'
import { CartItems, Checkout } from './utils'

import axios from 'axios'
import I18n from 'i18n'
import { store_api_orders_path } from 'routes'

const StoreHome = ({ logoUrl }) => {
  const [cartItems, setCartItems] = useState<Item[]>([])
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false)

  const addItem = (item: Item) => {
    setCartItems((prevCartItems) => [...prevCartItems, item])
  }

  const clearCart = () => {
    setCartItems([])
  }

  const onSubmitOrder = (orderAttrs) => {
    const items = cartItems.map((cartItem) => ({
      product_id: cartItem.productId,
      options: cartItem.options,
      price_excluding_vat: cartItem.price.excluding_vat
    }))

    return axios
      .post(store_api_orders_path({ locale: I18n.locale }), { order: { ...orderAttrs, items } })
      .then(clearCart)
  }

  return (
    <CartItems.Provider value={{ cartItems, addItem, clearCart }}>
      <Checkout.Provider value={{ openCheckoutModal: () => setCheckoutModalOpen(true) }}>
        <div className='store'>
          <NavBar logoUrl={logoUrl} />
          <Products />
        </div>
      </Checkout.Provider>

      {checkoutModalOpen && (
        <CheckoutModal
          onSubmit={(orderAttrs) => onSubmitOrder(orderAttrs)}
          onClose={() => setCheckoutModalOpen(false)}
        />
      )}
    </CartItems.Provider>
  )
}

export default StoreHome
