import React from 'react'

import I18n from 'i18n'
import { root_path } from 'routes'

const AVAILABLE_LOCALES = ['lv', 'en', 'ru']

const LocaleSelector = () => {
  return (
    <div className='header__locales'>
      <div className='btn-group dropleft'>
        <a className='locale' data-toggle='dropdown' href='#'>
          {I18n.locale.toUpperCase()}
        </a>
        <div className='dropdown-menu'>
          {AVAILABLE_LOCALES.map((locale) => (
            <a className='dropdown-item' href={`${root_path}${locale}`} key={locale}>
              {locale.toUpperCase()}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LocaleSelector
