// Based on: https://github.com/aniketgargya/react-axios-hooks/blob/master/lib/index.ts
import { useState } from 'react'

import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios'

interface IState<T> {
  loading: boolean
  data: T | null
  error: any | null
}

export function useLazyAxios<T = any>(): [(config: AxiosRequestConfig) => void, IState<T>] {
  const [state, setState] = useState<IState<T>>({
    loading: false,
    data: null,
    error: null
  })

  const sendRequest = (config: AxiosRequestConfig): void => {
    config.method ||= 'GET' as Method
    setState({
      loading: true,
      data: null,
      error: null
    })

    axios(config)
      .then((response) => {
        setState({
          loading: false,
          data: response.data,
          error: null
        })
      })
      .catch((err) => {
        setState({
          loading: false,
          data: null,
          error: err
        })
      })
  }

  return [sendRequest, state]
}

export type { AxiosRequestConfig, AxiosResponse }
