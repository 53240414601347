import { useCallback, useState } from 'react'

type ReturnType = readonly [boolean, () => void, () => void, () => void]

export const useToggler = (initialState: boolean): ReturnType => {
  const [value, setValue] = useState(initialState)

  const toggleValue = useCallback(() => setValue((prev) => !prev), [])
  const enable = useCallback(() => setValue(() => true), [])
  const disable = useCallback(() => setValue(() => false), [])

  return [value, toggleValue, enable, disable] as const
}
